<template>
  <div class="title unselectable">KlNANU</div>
  <div class="sub-title unselectable">software development</div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@font-face {
  font-family: 'Dolly Dots';
  src: url('/fonts/dollydots.ttf');
}

@font-face {
  font-family: 'Montserat';
  src: url('/fonts/montserrat.ttf');
}

html, body {
  font-family: sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #000000;
  color: #999999;
  margin-top: 10%;
  cursor: default;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.title {
  font-family: "Dolly Dots", sans-serif;
  font-size: 8rem;
  font-weight: 800;
}

.sub-title {
  color: #888888;
  margin-left: -7px;
  margin-top: -15px;
  font-size: 2rem;
  font-family: "Montserat", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

@media (max-width: 30em) {
  html, body {
    margin-top: 30%;
  }

  .title {
    font-size: 4rem;
  }

  .sub-title {
    margin-top: -10px;
    font-size: 1rem;
  }
}

</style>
